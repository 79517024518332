<script>
export default {
  name: 'Services'
}
</script>
<template>
  <div id="livraison" class="page sub_page text-center">
    <div class="title_page mx-auto mb-10">La livraison n'a jamais été aussi simple !</div>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-1/2">
      <div class="livraison_step flex">
        <div class="ml-5">
          <span>1</span>
        </div>
        <div class="text-left mx-4">
          <div>Passez commande au <nobr>05 46 04 01 02.</nobr></div>
          <div class="text-gray-400 text-lg text-justify">
            Chez Thomas c'est 5000 références (épicerie, produits frais, produits non alimentaire, boisson).
            <br/> Prise de commande tous les jours, par téléphone au <strong class="text-red-600">05 46 04 01 02</strong> ou par e-mail <a href="mailto:contact@chezthomasberton.fr" class="text-red-600">contact@chezthomasberton.fr</a>.
            <br/><br/>Pas de frais de facturation ! pas de minimum de commande ! n'hésitez plus passer commande.
            </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block lg:w-1/2">
      <img :src="require('../assets/images/phone.jpg')" class="livraison_step_1">
    </div>
    <div class="hidden lg:block lg:w-1/2 px-2">
      <img :src="require('../assets/images/shopping.jpg')" class="livraison_step_2">
    </div>
    <div class="w-full lg:w-1/2">
      <div class="livraison_step flex px-4">
        <div class="text-center">
          <span>2</span>
        </div>
        <div class="text-left mx-4">
          <div>Nous préparons votre commande.</div>
          <div class="text-gray-400 text-lg text-justify">
            Nous préparons votre commande avec le plus grand soin.
            Nous ajustons les quantités selon vos besoins, envies et habitudes.
            Des conditionnements adaptés à la restauration à domicile, facile de transport et écologique.
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-1/2">
      <div class="livraison_step flex px-4">
        <div class="text-center">
          <span>3</span>
        </div>
        <div class="text-left mx-4">
          <div>Drive ou Livraison.</div>
          <div class="text-gray-400 text-lg text-justify">
            Lors de la prise de commande choisissez le jour, la plage horaire et le mode de retrait.
            <br/><br/>Si vous passez commande avant 18h, le retrait en magasin est possible le jours même !
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block lg:w-1/2">
      <img :src="require('../assets/images/drive.jpg')" class="livraison_step_2">
    </div>
    <div class="mt-3 flex">
      <div class="hidden lg:block lg:w-1/2 px-2">
        <img :src="require('../assets/images/meal.jpg')" class="livraison_step_4 rounded" />
      </div>
      <div class="w-full lg:w-1/2">
        <div class="livraison_step flex px-4">
            <div class="text-center">
              <span>4</span>
            </div>
            <div class="text-left mx-4">
              <div>Préparez et Déguster.</div>
              <div class="text-gray-400 text-lg text-justify">

                Partager un bon moment et profiter de bons produits pour ravir vos papilles.
                <br/>Retrouver le gout de la cuisine et les saveurs d’antan grâce à nos produits issus de l’agriculture locales.
                <br/><br>
                Simple à mettre en place, bon et surtout un gain de temps !
              </div>
            </div>
        </div>
      </div>

    </div>
  </div>
  </div>
</template>
<style lang="scss">

@import "../assets/css/color";

.livraison_step {
  font-family: "Oswald";
  font-size: 32px;
  margin: 5% 0 0 0;
}

.livraison_step span {
  padding: 5px;
  background: #8d1f22;
  color: #FFF;
  width: 50px;
  height: auto;
  margin: 0;
  display: inline-block;
  border-radius: 50%;
}

.livraison_step_1 {
  width: auto;
  height: auto;
  @apply shadow-lg mx-auto rounded;
}
.livraison_step_2 {
  width: auto;
  height: auto;
  @apply shadow-lg mx-auto rounded;
}

.livraison_step_1_icon{
  margin: 25% 0 0 0;
}
.livraison_step_2_icon{
  margin: 25% 0 0 0;
  transform: scaleX(-1);
}

</style>