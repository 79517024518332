<script>
import * as Cookies from 'tiny-cookie'

export default {
  name: 'ConsentCookie',
  data(){
    return {
      show: true
    }
  },
  computed: {
    hasConsent: function(){
      return Cookies.getCookie('consent-cookie') !== null
    }
  },
  methods:{
    accept: function(){
      this.createCookie(true)
    },
    declined: function(){
      this.createCookie(false)
      this.disabledAnalytic()
    },
    disabledAnalytic: function(){
      this.$gtag.config({'UA-205628629-1': {'send_page_view': false}})
      this.$gtag.set({'allow_ad_personalization_signals': false})
      window['ga-disable-UA-205628629-1'] = true;

    },
    createCookie: function(data){
      const now = new Date();
      now.setFullYear(now.getFullYear() + 1)
      Cookies.setCookie('consent-cookie', data, { expires: now.toGMTString() })
      this.show = false
    },
  },
  mounted(){
    if(this.hasConsent){
      this.show = false
    }

    if(Cookies.getCookie('consent-cookie') ==="false"){
      this.disabledAnalytic()
    }
  }
}
</script>
<template>
  <div class="flex" v-if="show">
    <div class="consent_block w-full p-4">
      <div class="text-2xl">C'est vous qui Décidez !</div>
      <p class="p-4">Notre site Chez Thomas utilise des cookies pour améliorer votre expérience sur notre site. En utilisant notre site, vous acceptez les cookies.</p>
      <div class="flex">
        <button type="button" @click="declined" class="bg-red-600 px-4 py-2 rounded text-white mr-6">Refuser</button>
        <button type="button" @click="accept" class="bg-gray-900 px-4 py-2 rounded text-white">Accepter & Fermer</button>
      </div>
    </div>
  </div>
</template>
<style>
.consent_block{
  position: fixed;
  bottom: 0;
  background: rgba(70,13,16,0.9);
  color: #FFFFFF;
  min-height: 80px;
}
</style>