<script>
export default {
  name: 'Accueil'
}
</script>
<template>
  <div id="accueil">
    <div class="accueil">
      <div>
        <div class="accueil_block items-center align-baseline flex justify-center">
          <div>
            <div class="accueil_titre">
              <img
                  :src="require('../assets/images/logog.png')" class="accueil_logo"
              />
            </div>
            <div class="text-white text-center mt-2 text-xl oswald">Votre commerce multi-services à Cercoux et alentour.</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<style lang="scss">

@import "../assets/css/color";

.accueil {
  min-height: 100vh;
  background: url("../assets/images/accueil.jpg") no-repeat fixed;
  padding: 200px 0 0 0;
}

.accueil_logo {
  width: 100%;
  max-width: 423px;
  height: auto;
  padding: 0 20px;
}
.accueil_block {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 20px 0;
}


</style>