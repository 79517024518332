<script>
export default {
  name: 'Histoire'
}
</script>
<template>
  <div id="histoire" class="page sub_page">

    <div class="flex flex-wrap lg:flex-no-wrap">
      <div class="w-full lg:w-1/2">
        <img :src="require('../assets/images/legume.jpg')"  class="shadow-lg histoire_photo">
        <div class="text-center mt-8"><a href="flyer2021.pdf" target="_blank" class="flyer">Retrouvez notre Flyer de rentrée 2021 en cliquant ici</a></div>
      </div>
      <div class="w-full lg:w-1/2 px-4">
        <div class="title_page">Notre histoire</div>
        <div class="text-justify roboto text-lg">
          <p class="histoire_p">Le commerce multi-services de Cercoux est le point de ralliement pour tous vos achats quotidiens des professionnels et des particuliers.
            <br/><br/>Fort d’une expérience de plus de 70 ans, l’entreprise T.BERTON est votre alliée pour vous accompagner et vous servir avec plus de <span class="text-red-600">5000 références</span>.
            <br/>Grace à nos multiples services, vous êtes assurés de trouver ce qu’il vous faut ! Notre entreprise se démarque par des produits de qualités, de nombreux services et un accueil chaleureux.
            <br/><br/>
            <span class="subtitle text-2xl">Le mot du gérant </span>
            <br/><br/>
            Bonjour à vous tous je m’appelle <span class="text-red-600">Thomas BERTON</span> et je suis le gérant.
            J’ai 20 ans et je reprends ce commerce multi-services qui se situe dans mon village natal de
            Cercoux. J’ai décidé de reprendre ce magasin car il m’a vu grandir et aujourd’hui c’est à mon
            tour de le faire traverser une nouvelle génération et d’apporter ma pierre à l’édifice.
            Ce commerce j’y suis et les cercousiens y sont attachés et c’est pour cela que je vais mener à
            bien mon projet tout en vous apportant satisfaction, qualité et toujours plus de services.
            A bientôt !

          </p>

        </div>
      </div>
    </div>

  </div>
</template>
<style lang="scss">

@import "../assets/css/color";

.histoire_p{
  width: 100%;
  padding: 10px;
  @apply text-gray-700;
  letter-spacing: -1px;
  font-size: 20px;
}

.histoire_photo{
  width: auto;
  height:auto;
  @apply rounded-2xl px-2;
}
</style>