<script>
import MenuSmall from "./MenuSmall";
export default {
  name: 'Menu',
  components:{
    MenuSmall
  },
  methods:{
    onClickEvent: function(menu){
      this.$gtag.pageview('/' + menu)

    },
  },
  mounted() {
    this.$gtag.pageview('/')
  }

}
</script>
<template>
  <div>
    <div  class="menu flex">
      <div class="w-full block lg:hidden">
        <div class="block lg:hidden ">
        <MenuSmall></MenuSmall>
      </div>
      </div>
      <div class="w-full text-right hidden lg:block">
        <ul class="menu_list ">
          <li><a href="#" @click="onClickEvent('accueil')" v-scroll-to="'#accueil'">Accueil</a></li>
          <li><a href="#" @click="onClickEvent('histoire')" v-scroll-to="'#histoire'">Notre histoire</a></li>
          <li><a href="#" @click="onClickEvent('services')" v-scroll-to="'#services'">Services</a></li>
          <li><a href="#" @click="onClickEvent('livraison')" v-scroll-to="'#livraison'">Livraison</a></li>
          <li><a href="#" @click="onClickEvent('contact')" v-scroll-to="'#contact'">Contact</a> </li>
        </ul>

      </div>
    </div>
  </div>
</template>
<style lang="scss">

@import "../assets/css/color";

.menu{
  background: $bgNavbar;
  width: 100%;
  height: 60px;
  position: fixed;
}

.menu_list {
  margin: 0;
  padding: 0 60px 0 0;
}
.menu_list li{
  display: inline-block;
}
.menu_list li a{
  padding: 18px 20px;
  display: inline-block;
  color: $colorMenu;

  &:hover{
    background: $colorMenuHover;
  }
}

.menu_small{

}
.tham-inner {
  background-color: #FFFFFF;
  color: #FFFFFF;
}


</style>