<script>
export default {
  name: 'MenuSmall',
  computed: {
    isOpened() {
      return  this.$parent.$parent.showMenu
    }
  },
  methods: {
    openMenu() {
      this.$parent.$parent.showMenu = true;
    },
    closeMenu() {
      this.$parent.$parent.showMenu = false;
    },
    toggleMenu() {

      if (this.isOpened) {
        this.closeMenu()
        return;
      }

      this.openMenu();
    },
    onClickEvent: function(menu){
      this.$gtag.pageview('/' + menu)
    },
  }
}
</script>
<template>
  <div>
    <div class="flex">
      <div class="w-auto">
        <div @click.stop="toggleMenu"
             class="tham tham-e-squeeze tham-w-8 mt-4 ml-4"
             :class="{ 'tham-active': isOpened }"
        >
          <div class="tham-box">
            <div class="tham-inner bg-white" />
          </div>
        </div>
      </div>
      <div class="w-full text-center">
        <div class="text-white mt-2 menu_small_title">Chez Thomas</div>
      </div>
    </div>
    <div class="menu_small" v-if="isOpened">
      <ul class="menu_small_list w-full text-center">
        <li><a href="#" @click="onClickEvent('accueil-mobile')"  v-scroll-to="'#accueil'">Accueil</a></li>
        <li><a href="#" @click="onClickEvent('histoire-mobile')"  v-scroll-to="'#histoire'">Notre histoire</a></li>
        <li><a href="#" @click="onClickEvent('services-mobile')" v-scroll-to="'#services'">Services</a></li>
        <li><a href="#" @click="onClickEvent('livraison-mobile')"  v-scroll-to="'#livraison'">Livraison</a></li>
        <li><a href="#" @click="onClickEvent('contact-mobile')"  v-scroll-to="'#contact'">Contact</a> </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss">
@import "../assets/css/color";

.menu_small_title{
  font-family: 'Style Script', sans-serif;
  font-size: 28px;
}
.menu_small{
  background: $bgNavbar;
  color: #FFFFFF;
  font-size: 26px;
  @apply w-full absolute mt-2 ;
}
.menu_small_list{
  font-family: "Oswald", sans-serif;
  padding: 5px;

}
</style>