<template>
  <div id="app" @click="onCLickBody">
    <Menu></Menu>
    <Accueil></Accueil>
    <Histoire></Histoire>
    <Services></Services>
    <Livraison></Livraison>
    <Contact></Contact>
    <ConsentCookie></ConsentCookie>
  </div>
</template>

<script>
import Vue from 'vue'
import Menu from './components/Menu.vue'
import Accueil from './components/Accueil.vue'
import Histoire from './components/Histoire.vue'
import Services from "./components/Services.vue";
import Livraison from "./components/Livraison.vue";
import Contact from "./components/Contact";
import ConsentCookie from "./components/ConsentCookie";
import VueScrollTo from 'vue-scrollto'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-205628629-1" }
});



Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -40,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

export default {
  name: 'App',
  data() {
    return {
      showMenu: false,
    }
  },
  components: {
    Contact,
    Menu,
    Accueil,
    Histoire,
    Services,
    Livraison,
    ConsentCookie
  },
  methods: {
    onCLickBody(){
      this.showMenu = false;

    }
  }
}
</script>

<style lang="scss">

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.page {
  width: 100%;
  min-height: calc(100vh - 60px);
  background: #fafafa;
}

.sub_page {
  padding: 60px 0 0 0;
}

.stylescript {
  font-family: 'Style Script', sans-serif;
}

.oswald {
  font-family: 'Oswald', sans-serif;
}

.lobster {
  font-family: 'Lobster', sans-serif;
}

.roboto {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
}

.title_page {
  @extend .lobster;
  font-size: 50px;
  color:#701316;
}
.flyer{
  color:#701316;
  font-size: 28px;
}
.subtitle {
  @extend .lobster;
  color:#701316;
}
</style>
